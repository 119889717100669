<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { tableData } from "../data-advancedtable";

/**
 * Gamefication Ranking component
 */
export default {
  page: {
    title: "Ranking Gameficação",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      tableData: tableData,
      title: "Ranking Gameficação",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Ranking Gameficação",
          active: true,
        },
      ],
      showModal: false,
      tableHighlighted: 0,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fieldAliases: {
        id: "#",
        image: "Foto",
        name: "Nome",
        status: "Status",
        points: "Pontos",
        level: "Nível",
      },
      selectedStudentName: [],
      selectedCourses: [],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
    fields() {
      return [
        {
          key: "id",
          sortable: true,
          label: this.fieldAliases.id,
          thStyle: { width: "8%" },
        },
        {
          key: "image",
          sortable: true,
          label: this.fieldAliases.image,
          thStyle: { width: "8%" },
        },
        { key: "name", sortable: true, label: this.fieldAliases.name },
        {
          key: "status",
          sortable: true,
          label: this.fieldAliases.status,
          thStyle: { width: "8%" },
        },
        {
          key: "points",
          sortable: true,
          label: this.fieldAliases.points,
          thStyle: { width: "12%" },
        },
        {
          key: "level",
          sortable: true,
          label: this.fieldAliases.level,
          thStyle: { width: "12%" },
        },
      ];
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Período</h4>
            <div class="d-flex justify-content-between">
              <div class="row">
                <div class="col">
                  <div class="d-flex align-items-center justify-content-around">
                    <div style="margin-right: 10px;">De</div>
                    <input
                      type="date"
                      class="form-control ml-2"
                      name="start_date"
                      id="start_date"
                      value="2023-09-14"
                    />
                  </div>
                </div>

                <div class="col">
                  <div class="d-flex align-items-center justify-content-around">
                    <span style="margin-right: 10px;">Até</span>
                    <input
                      type="date"
                      class="form-control"
                      name="end_date"
                      id="end_date"
                      value="2023-09-14"
                    />
                  </div>
                </div>

                <div class="col-12 col-md-2">
                  <button
                    type="button"
                    class="btn btn-block btn-primary"
                    onclick="refreshTable()"
                  >
                    Filtro
                  </button>
                </div>
              </div>

              <!-- NOTIFICAÇÕES -->
              <div>
                <a
                  class="nav-link dropdown-toggle arrow-none btn-notification"
                  data-toggle="dropdown"
                  id="topbar-notification"
                  href="#"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div class="position-relative">
                    <i class="fa fa-bell" style="font-size: x-large"></i>
                    <span
                      class="total_pending bg-danger rounded-circle position-absolute text-center"
                      style="
                        width: 20px;
                        height: 20px;
                        bottom: 19px;
                        right: 10px;
                        color: white;
                      "
                      >0</span
                    >
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Ranking da Gameficação</h4>
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Mostrar
                    <b-form-select
                      class="form-control form-control-sm form-select form-select-sm"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select>
                    Registros
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Pesquisar:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Digite algo..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 datatables">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <!-- Coluna "image" personalizada -->
                <template #cell(image)="obj">
                  <div class="actions-cell">
                    <b-img
                      rounded="circle"
                      :src="require('@/assets/images/small/img-3.jpg')"
                      :name="obj.item.image"
                      width="70"
                      height="70"
                      alt="70x70"
                    ></b-img>
                  </div>
                </template>
                <template #cell(status)="obj">
                  <div class="actions-cell">    
                    <span class="badge ms-1" :class="obj.item.status == 'A' ? 'bg-success' : 'bg-warning'">
                      <h6 class="m-0">{{obj.item.status == "A" ? "Ativo" : "Inativo"}}</h6>
                    </span>                   
                  </div>
                </template>
                <!-- Coluna "level" personalizada -->
                <template #cell(level)="obj">
                  <div class="actions-cell">
                    <div class="actions-cell">
                      <b-img
                        rounded="circle"
                        :src="require('@/assets/images/small/img-3.jpg')"
                        :name="obj.item.image"
                        width="70"
                        height="70"
                        alt="70x70"
                      ></b-img>
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
