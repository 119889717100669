const tableData = [
  {
    id: 1,
    image: 'src/test.jpg',
    name: 'John Doe',
    status: 'A',
    points: 5,
    level: 'D',
  }, {
    id: 2,
    image: 'src/test.jpg',
    name: 'Quality Engineer',
    status: 'I',
    points: 13,
    level: 'G',
  }, {
    id: 3,
    image: 'src/test.jpg',
    name: 'Senior Editor',
    status: 'A',
    points: 2,
    level: 'P',
  }, {
    id: 4,
    image: 'src/test.jpg',
    name: 'Programmer II',
    status: 'A',
    points: 7,
    level: 'C',
  },
]

export { tableData };
